// Colours
export const colours = {
  orange: '#FF7057',
  orangeDark: '#CC5A46',
  orangeLight: '#fff0ee',
  blue: '#001E3F',
  blueDark: '#01142B',
  allowaBlue: '#0E47C1',
  allowaBlueDark: '#0B399A',
  lightBlue: '#F1F4FC',
  lightBlueDark: '#ECF0FA',
  green: '#51D1AD',
  allowaGreen: '#26C598',
  allowaGreenDark: '#1C9674',
  offwhite: '#FAFAFA',
  black: '#0A0A0A',
  grey: '#7C8C9F',
}

// Breakpoints
export const size = {
  smartphone: '767px',
  tablet: '768px',
  desktop: '1024px',
}

export const device = {
  smartphone: `(max-width: ${size.smartphone})`,
  tablet: `(min-width: ${size.tablet})`,
  desktop: `(min-width: ${size.desktop})`,
}
